import { useTranslation } from 'react-i18next'

export const RenderLocalizedContent = ({
	en,
	sk,
	cz,
}: {
	en?: string
	sk?: string
	cz?: string
}) => {
	const { i18n } = useTranslation()
	const language = i18n.language

	if (language === 'sk' && sk) {
		return sk
	}

	if (language === 'cz' && cz) {
		return cz
	}

	return en
}
